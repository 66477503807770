import React from 'react';

// logo, browse, my profile, login
function Header() {
    return (
        <div className='header'>
            <h1>Logo goes here</h1>
            <h1>Browse</h1>
            <h1>My Profile</h1>
            <h1>Log in</h1>
        </div>
    )
}

// hello world

export default Header;